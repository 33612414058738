<template>
  <div class="loader-container" v-if="isLoading || !task">
    <ip-loader />
  </div>
  <div class="task" v-else>
    <div class="task__head">
      <div class="title">{{ pageTitle }}</div>
      <div class="user">
        <inline-svg :src="require('../assets/icons/home_24px_outlined.svg')" />
        <router-link :to="{ name: 'user', params: { id: task.user.id } }"> {{ getUserName(task.user) }} </router-link>
      </div>
    </div>
    <component :is="taskBodyComponent" :task="task"/>
    <div class="task__footer" v-if="isShowTaskFooter">
      <task-footer :task="task"/>
    </div>
  </div>
</template>

<script>
import apiRoutes from '../consts/apiRoutes';
import IpLoader from "../components/IpLoader";
import TaskFooter from "../components/Task/TaskFooter";
import CommonMixin from "../mixins/Common.mixin";

import ChatBody from "../components/Task/TaskBody/ChatBody";
import ReviewBody from "../components/Task/TaskBody/ReviewBody";
import ContractBody from "../components/Task/TaskBody/ContractBody";
import UserDocumentBody from "../components/Task/TaskBody/UserDocumentBody";
import ObjectDocumentBody from "../components/Task/TaskBody/ObjectDocumentBody";
import FakeObjectBody from "../components/Task/TaskBody/FakeObjectBody";
import DefaultBody from "../components/Task/TaskBody/DefaultBody";

const taskTypes = {
  chat_abuse: 'Жалоба из чата',
  review_dispute: 'Спорный отзыв',
  contract_dispute: 'Спор при прекращении договора',
  user_documents_verification: 'Проверка личных документов',
  object_documents_verification: 'Проверка документов на недвижимость',
  else: 'Другое',
  consultation: 'Консультация',
  law_and_security: 'Юридические или вопросы безопасности',
  fake_object: 'Жалоба на обьект'
};

export default {
  name: 'task',

  components: {
    IpLoader,
    TaskFooter,
    ChatBody,
    ReviewBody,
    ContractBody,
    UserDocumentBody,
    ObjectDocumentBody,
    FakeObjectBody,
    DefaultBody
  },

  mixins: [ CommonMixin ],

  data() {
    return {
      task: null,
      isLoading: true
    };
  },

  async mounted() {
    try {
      const result = await this.axios.get(`${apiRoutes.task}/${this.$route.params.id}`);
      this.task = result.data;
      this.isLoading = false;
    } catch(error) {
      console.warn(error);
      this.isLoading = false;
    }
  },

  computed: {
    pageTitle() {
      if (!this.task) {
        return '';
      }

      return taskTypes[this.task.type];
    },

    taskBodyComponent() {
      if (!this.task) {
        return '';
      }
      switch (this.task.type) {
        case 'chat_abuse': {
          return 'ChatBody';
        }
        case 'review_dispute': {
          return 'ReviewBody';
        }
        case 'contract_dispute': {
          return 'ContractBody';
        }
        case 'user_documents_verification': {
          return 'UserDocumentBody';
        }
        case 'object_documents_verification': {
          return 'ObjectDocumentBody';
        }
        case 'fake_object': {
          return 'FakeObjectBody';
        }
        default: {
          return 'DefaultBody';
        }
      }
    },

    isShowTaskFooter() {
      return !['else', 'consultation', 'law_and_security'].includes(this.task.type);
    }
  }
}
</script>

<style scoped lang="scss">
.task{
  display: flex;
  flex-direction: column;
  height: 100%;
  &__head{
    margin-bottom: 10px;
    flex: none;
    border-radius: 4px;
    padding: 16px 24px;
    background: $white;
    box-shadow: 0px 25px 50px -20px rgba($black, 0.1);
    .title{
      font-style: normal;
      font-weight: bold;
      font-size: 24px;
      line-height: 29px;
      color: $black;
      margin-bottom: 4px;
    }
    .user{
      display: flex;
      align-items: center;
      ::v-deep svg{
        margin-right: 6px;
      }
    }
  }
  &__body{
    flex: 1 1 auto;
    margin-bottom: 10px;
  }
  &__footer{
    flex: none;
    border-radius: 4px;
    border-radius: 4px;
    padding: 16px 24px;
    background: $white;
    box-shadow: 0px 25px 50px -20px rgba($black, 0.1);
  }
}
</style>