<template>
  <div class="default__body">
    <div class="default__body__title">
      Описание проблемы
    </div>
    <div class="default__body__description">
      {{ task.data.problem }}
    </div>
    <div class="default__body__title">
      Предложенное решение
    </div>
    <div class="default__body__description">
      {{ task.data.solution }}
    </div>
  </div>
</template>

<script>

export default {
  props: {
    task: {
      type: Object,
      required: true
    }
  }
}
</script>

<style scoped lang="scss">
.default__body{
  border-radius: 4px;
  background: $white;
  height: 100%;
  box-shadow: 0px 25px 50px -20px rgba($black, 0.1);
  padding: 24px;
  &__title{
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 29px;
    color: $black;
    margin-bottom: 10px;
  }
  &__description{
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 17px;
    color: $black;
    margin-bottom: 16px;
  }

}
</style>