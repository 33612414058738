<template>
  <div class="wrapper__body">
    <div class="wrapper__body__elem">
      <div class="wrapper__body__elem__title">
        Данные
      </div>
      <div class="wrapper__body__elem__info">
        <div class="wrapper__body__elem__info__title">
          Жалоба на
        </div>
        <div class="wrapper__body__elem__info__description">
          <router-link :to="{ name: 'user', params: { id: task.data.review.from.id } }">
            {{ userName }}
          </router-link>
        </div>
        <div class="wrapper__body__elem__info__title">
          Комментарий
        </div>
        <div class="wrapper__body__elem__info__description">
          {{ task.data.description }}
        </div>
      </div>
    </div>
    <div class="wrapper__body__elem">
      <div class="wrapper__body__elem__title">
        Отзыв
      </div>
      <div class="wrapper__body__elem__info">
        <reviews-item :review="task.data.review" />
      </div>
    </div>
  </div>
</template>

<script>
import ReviewsItem from "../../ReviewsItem";

export default {
  props: {
    task: {
      type: Object,
      required: true
    }
  },

  components: {
    ReviewsItem
  },

  computed: {
    userName() {
      return `${this.task.data.review.from.details.firstName || ''} ${this.task.data.review.from.details.lastName || ''}`;
    }
  }
}
</script>

<style scoped lang="scss">
.wrapper__body{
  background: transparent;
}
</style>