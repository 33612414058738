<template>
  <div class="wrapper__body object-document">
    <div class="wrapper__body__elem">
      <div class="wrapper__body__elem__title">
        Введённые данные
      </div>
       <div class="wrapper__body__elem__info">
         <div class="object-document__info">
          <div class="object-document__elem w50">
            <div class="wrapper__body__elem__info__title">
              Город
            </div>
            <div class="wrapper__body__elem__info__description">
              {{ task.data.object.city && task.data.object.city.name_ru || '-' }}
            </div>
            <div class="wrapper__body__elem__info__title">
              Район
            </div>
            <div class="wrapper__body__elem__info__description">
              {{ task.data.object.district && task.data.object.district.name_ru || '-' }}
            </div>
            <div class="wrapper__body__elem__info__title">
              Улица
            </div>
            <div class="wrapper__body__elem__info__description">
              {{ task.data.object.street && task.data.object.street.name_ru || '-' }}
            </div>
            <div class="wrapper__body__elem__info__title">
              Дом
            </div>
            <div class="wrapper__body__elem__info__description">
              {{ task.data.object.building || '-' }}
            </div>
            <div class="wrapper__body__elem__info__title">
              Стоимость в месяц
            </div>
            <div class="wrapper__body__elem__info__description">
              {{ task.data.object.details && task.data.object.details.rentPrice || '-' }}
            </div>
            <div class="wrapper__body__elem__info__title">
              Валюта
            </div>
            <div class="wrapper__body__elem__info__description">
              грн
            </div>
            <div class="wrapper__body__elem__info__title">
              Срок аренды
            </div>
            <div class="wrapper__body__elem__info__description">
              {{ rentPeriod || '-' }}
            </div>
            
          </div>
          <div class="object-document__elem w50">
            <div class="wrapper__body__elem__info__title">
              Тип недвижимости
            </div>
            <div class="wrapper__body__elem__info__description">
              {{ type || '-' }}
            </div>
            <div class="wrapper__body__elem__info__title">
              Комнат
            </div>
            <div class="wrapper__body__elem__info__description">
              {{ task.data.object.details && task.data.object.details.roomsCount || '-' }}
            </div>
            <div class="wrapper__body__elem__info__title">
              Площадь общая
            </div>
            <div class="wrapper__body__elem__info__description">
              {{ task.data.object.details && task.data.object.details.square || '-' }} м2
            </div>
            <div class="wrapper__body__elem__info__title">
              Площадь жилая
            </div>
            <div class="wrapper__body__elem__info__description">
              {{ task.data.object.details && task.data.object.details.squareLiving || '-' }} м2
            </div>
            <div class="wrapper__body__elem__info__title">
              Площадь кухни
            </div>
            <div class="wrapper__body__elem__info__description">
              {{ task.data.object.details && task.data.object.details.squareKitchen || '-' }} м2
            </div>
            <div class="wrapper__body__elem__info__title">
              Этаж
            </div>
            <div class="wrapper__body__elem__info__description">
              {{ task.data.object.details && task.data.object.details.floor || '-' }}
            </div>
            <div class="wrapper__body__elem__info__title">
              Этажность дома
            </div>
            <div class="wrapper__body__elem__info__description">
              {{ task.data.object.details && task.data.object.details.floorCount || '-' }}
            </div>
            
          </div>
          <div class="object-document__elem">
            <div class="wrapper__body__elem__info__title">
              Описание
            </div>
            <div class="wrapper__body__elem__info__description">
              {{ task.data.object.details && task.data.object.details.description || '-' }}
            </div>
            
          </div>
         </div>
      </div> 
    </div>
    <div class="wrapper__body__elem">
      <div class="wrapper__body__elem__title">
        Фото и видео
      </div>
      <div class="wrapper__body__elem__info">
        <div
          v-for="(file, index) in files"
          :key="index">
          <video v-if="file.type === 'video'" :src="file.url" controls></video>
          <img
            v-else
            :src="file.url"
            :alt="'file-'+index">
        </div>
      </div>
    </div>
  </div>
</template>

<script>

const rentPeriods = {
  "from_3_months": "от 3 мес",
  "from_6_months": "от 6 мес",
  "from_1_year": "от 1 года"
};

const types = {
  "apartment": "квартира",
  "house": "дом",
  "office": "офис"
};

export default {
  props: {
    task: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      config: {
        toolbar: {
          toolbarViewerLeft: { 
            findbar: false 
          },
          toolbarViewerRight: false,
          sidebar: false
        },
        secondaryToolbar: false
      }
    };
  },

  computed: {
    rentPeriod() {
      if (!this.task.data.object.details) {
        return '';
      }

      return rentPeriods[this.task.data.object.details.rentPeriod];
    },

    type() {
      return types[this.task.data.object.type];
    },

    files() {
      const files = [];

      this.task.data.object.videos && this.task.data.object.videos.forEach(video => {
        files.push({url: video.url, type: 'video'})
      });
      this.task.data.object.photos && this.task.data.object.photos.forEach(photo => {
        files.push({url: photo.url, type: 'image'})
      });
      this.task.data.object.layouts && this.task.data.object.layouts.forEach(photo => {
        files.push({url: photo.url, type: 'layout'})
      });
      return files;
    }
  }
  
}
</script>

<style scoped lang="scss">
.object-document{
  img,
  video{
    width: 100%;
    margin-bottom: 8px;
  }
  video{
    max-height: 300px;
  }
  .wrapper__body__elem__info{
    padding: 0;
  }
  .wrapper__body__elem__title{
    margin-bottom: 16px;
  }
  .object-document__elem{
    border-top: 1px solid $alto;
    width: 100%;
    padding: 16px 24px;
    &.w50{
      width: 50%;
      &:first-child{
        border-right: 1px solid $alto;
      }
    }
  }
  .object-document__info{
    display: flex;
    flex-wrap: wrap;
  }
}
</style>