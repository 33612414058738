<template>
  <div class="wrapper__body object-document">
    <div class="wrapper__body__elem">
      <div class="wrapper__body__elem__title">
        Введённые данные
      </div>
       <div class="wrapper__body__elem__info">
         <div class="object-document__info">
          <div class="object-document__elem w50">
            <div class="wrapper__body__elem__info__title">
              Город
            </div>
            <div class="wrapper__body__elem__info__description">
              {{ task.data.object.city && task.data.object.city.name_ru || '-' }}
            </div>
            <div class="wrapper__body__elem__info__title">
              Район
            </div>
            <div class="wrapper__body__elem__info__description">
              {{ task.data.object.district && task.data.object.district.name_ru || '-' }}
            </div>
            <div class="wrapper__body__elem__info__title">
              Улица
            </div>
            <div class="wrapper__body__elem__info__description">
              {{ task.data.object.street && task.data.object.street.name_ru || '-' }}
            </div>
            <div class="wrapper__body__elem__info__title">
              Дом
            </div>
            <div class="wrapper__body__elem__info__description">
              {{ task.data.object.building || '-' }}
            </div>
            <div class="wrapper__body__elem__info__title">
              Стоимость в месяц
            </div>
            <div class="wrapper__body__elem__info__description">
              {{ task.data.object.details && task.data.object.details.rentPrice || '-' }}
            </div>
            <div class="wrapper__body__elem__info__title">
              Валюта
            </div>
            <div class="wrapper__body__elem__info__description">
              грн
            </div>
            <div class="wrapper__body__elem__info__title">
              Срок аренды
            </div>
            <div class="wrapper__body__elem__info__description">
              {{ rentPeriod || '-' }}
            </div>
            
          </div>
          <div class="object-document__elem w50">
            <div class="wrapper__body__elem__info__title">
              Тип недвижимости
            </div>
            <div class="wrapper__body__elem__info__description">
              {{ type || '-' }}
            </div>
            <div class="wrapper__body__elem__info__title">
              Комнат
            </div>
            <div class="wrapper__body__elem__info__description">
              {{ task.data.object.details && task.data.object.details.roomsCount || '-' }}
            </div>
            <div class="wrapper__body__elem__info__title">
              Площадь общая
            </div>
            <div class="wrapper__body__elem__info__description">
              {{ task.data.object.details && task.data.object.details.square || '-' }} м2
            </div>
            <div class="wrapper__body__elem__info__title">
              Площадь жилая
            </div>
            <div class="wrapper__body__elem__info__description">
              {{ task.data.object.details && task.data.object.details.squareLiving || '-' }} м2
            </div>
            <div class="wrapper__body__elem__info__title">
              Площадь кухни
            </div>
            <div class="wrapper__body__elem__info__description">
              {{ task.data.object.details && task.data.object.details.squareKitchen || '-' }} м2
            </div>
            <div class="wrapper__body__elem__info__title">
              Этаж
            </div>
            <div class="wrapper__body__elem__info__description">
              {{ task.data.object.details && task.data.object.details.floor || '-' }}
            </div>
            <div class="wrapper__body__elem__info__title">
              Этажность дома
            </div>
            <div class="wrapper__body__elem__info__description">
              {{ task.data.object.details && task.data.object.details.floorCount || '-' }}
            </div>
            
          </div>
          <div class="object-document__elem">
            <div class="wrapper__body__elem__info__title">
              Описание
            </div>
            <div class="wrapper__body__elem__info__description">
              {{ task.data.object.details && task.data.object.details.description || '-' }}
            </div>
            
          </div>
         </div>
      </div> 
    </div>
    <div class="wrapper__body__elem">
      <div class="wrapper__body__elem__title">
        Прикреплённые сканы
      </div>
      <div class="wrapper__body__elem__info">
        <div
          v-for="(document, index) in task.data.documents"
          :key="index">
          <vue-pdf-app
            style="height: 400px;"
            v-if="document.extension === 'pdf'"
            :pdf="documentBuffer(document)"
            :config="config" />
          <img
            v-else
            :src="documentBuffer(document)"
            :alt="'document-'+index">
        </div>
      </div>
    </div>
  </div>
</template>

<script>

const rentPeriods = {
  "from_3_months": "от 3 мес",
  "from_6_months": "от 6 мес",
  "from_1_year": "от 1 года"
};

const types = {
  "apartment": "квартира",
  "house": "дом",
  "office": "офис"
};

export default {
  props: {
    task: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      config: {
        toolbar: {
          toolbarViewerLeft: { 
            findbar: false 
          },
          toolbarViewerRight: false,
          sidebar: false
        },
        secondaryToolbar: false
      }
    };
  },

  computed: {
    rentPeriod() {
      if (!this.task.data.object.details) {
        return '';
      }

      return rentPeriods[this.task.data.object.details.rentPeriod];
    },

    type() {
      return types[this.task.data.object.type];
    },
  },

  methods: {
    documentBuffer(document) {
    
      if (document.extension === 'pdf') {
        const buffer = new Buffer.from(document.buffer, 'base64');

        return buffer.buffer;
      }


      const buffer = new Buffer.from(document.buffer, 'base64').toString('base64');

      return `data:image/${document.extension};base64,${buffer}`;
    }
  },

  
}
</script>

<style scoped lang="scss">
.object-document{
  img{
    width: 100%;
    margin-bottom: 8px;
  }
  .wrapper__body__elem__info{
    padding: 0;
  }
  .wrapper__body__elem__title{
    margin-bottom: 16px;
  }
  .object-document__elem{
    border-top: 1px solid $alto;
    width: 100%;
    padding: 16px 24px;
    &.w50{
      width: 50%;
      &:first-child{
        border-right: 1px solid $alto;
      }
    }
  }
  .object-document__info{
    display: flex;
    flex-wrap: wrap;
  }
}
</style>