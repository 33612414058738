<template>
  <div class="wrapper__body chat">
    <div class="wrapper__body__elem">
      <div class="wrapper__body__elem__title">
        Данные
      </div>
      <div class="wrapper__body__elem__info">
        <div class="wrapper__body__elem__info__title">
          Жалоба на
        </div>
        <div class="wrapper__body__elem__info__description">
          <router-link :to="{ name: 'user', params: { id: task.data.reportedUser.id } }">
            {{ userName }}
          </router-link>
        </div>
        <div class="wrapper__body__elem__info__title">
          Тематика
        </div>
        <div class="wrapper__body__elem__info__description">
          {{ theme }}
        </div>
        <div class="wrapper__body__elem__info__title">
          Комментарий
        </div>
        <div class="wrapper__body__elem__info__description">
          {{ task.data.description }}
        </div>
      </div>
    </div>
    <div class="wrapper__body__elem">
      <div class="wrapper__body__elem__title">
        Актуальный чат
      </div>
      <div class="wrapper__body__elem__info">
        <div class="messages-group"
            v-for="(group, index) in groups"
            :key="index">
          <div 
            class="messages-group-date">
            {{ group.date }}
          </div>
          <div
            v-for="(message, index) in group.messages"
            :key="index"
            class="chat-message"
            :class="{'owner': isOwner(message.sender)}">
            <div class="message">
              <div>{{ message.text }}</div>
              <div v-if="message.attachmentUrl" class="attachment">
                <img v-if="isMessage(message.attachmentUrl)" :src="message.attachmentUrl" :alt="message.attachmentUrl"/>
                <a v-else :href="message.attachmentUrl" target="_blank">{{ message.attachmentUrl }}</a>
              </div>
            </div>
            <div class="info">
              <div class="date">
                {{  formatTime(message.timestamp) }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

const chatTheme = {
  spam: 'Спам',
  offense: 'Оскорбления',
  abuse: 'Угрозы',
  else: 'Другое'
}

export default {
  props: {
    task: {
      type: Object,
      required: true
    }
  },

  computed: {
    userName() {
      return `${this.task.data.reportedUser.details.firstName || ''} ${this.task.data.reportedUser.details.lastName || ''}`;
    },

    partner() {
      return this.task.data.chat.partner;
    },

    messages() {
      return this.task.data.chat.messages;
    },

    groups() {
      let groups = [];

      this.messages.forEach(message => {
        const date = this.$moment(message.timestamp).format('DD.MM.YYYY');
        let target = groups.find(group => group.date === date);

        if (target) {
          target.messages.push(message);
          return;
        }

        groups.push({date, timestamp: message.timestamp, messages: [message]});

      });

      groups.forEach(group => {
        group.messages = group.messages.sort((a, b) => this.$moment(a.timestamp).unix() > this.$moment(b.timestamp).unix() ? 1 : -1);
      })

      return groups.sort((a, b) => this.$moment(a.timestamp).unix() > this.$moment(b.timestamp).unix() ? 1 : -1);
    },

    theme() {
      return chatTheme[this.task.data.theme];
    }
  },

  methods: {
    formatTime(time) {
      return this.$moment(time).format('HH:mm:ss');
    },

    isOwner(sender) {
      return sender.id !== this.partner.id;
    }
  }
}
</script>

<style scoped lang="scss">
.chat{
  .messages-group{
    &-date{
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 14px;
      color: $raven;
      margin-bottom: 16px;
      text-align: center;
    }
  }
  .chat-message{
    margin-bottom: 9px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    &.owner{
      align-items: flex-end;
      .message {
        background: $scienceBlue;
        border-radius: 10px 10px 0 10px;
      }
    }
    .message{
      word-break: break-word;
      max-width: 263px;
      border-radius: 10px 10px 10px 0;
      background: $raven;
      padding: 4px 10px;
      margin-bottom: 4px;
      color: $white;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 17px;
      a{
        color: $white;
      }
      img{
        margin-top: 8px;
        width: 100%;
      }
    }
    .info{
      display: flex;
      align-items: center;
      justify-content: flex-end;
      .date{font-style: normal;
        font-weight: normal;
        font-size: 10px;
        line-height: 12px;
        color: $raven;
        margin-left: 2px;
      }
    }
  }
}
</style>