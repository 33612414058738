const chat_abuse = {
  decisions: [
    {
      text: 'Не выбрано',
      value: ''
    },
    {
      text: 'Подтвердить',
      value: 'accepted'
    },
    {
      text: 'Отклонить',
      value: 'rejected'
    }
  ],
  reasons: [
    {
      text: 'Не выбрано',
      value: ''
    },
    {
      text: 'Нарушения не найдены',
      value: 'no_violation'
    },
    {
      text: 'Заблокировать юзера до выяснения',
      value: 'temporary_ban'
    },
    {
      text: 'Бан юзера',
      value: 'permanent_ban'
    }
  ]
};

const review_dispute = {
  decisions: [
    {
      text: 'Не выбрано',
      value: ''
    },
    {
      text: 'Подтвердить',
      value: 'accepted'
    },
    {
      text: 'Отклонить',
      value: 'rejected'
    }
  ],
  reasons: [
    {
      text: 'Не выбрано',
      value: ''
    },
    {
      text: 'Нарушения не найдены',
      value: 'no_violation'
    },
    {
      text: 'Удалить отзыв',
      value: 'delete_review'
    },
    {
      text: 'Удалить отзыв и заблокировать юзера до выяснения',
      value: 'delete_review_temporary_ban'
    },
    {
      text: 'Удалить отзыв и бан юзера',
      value: 'delete_review_permanent_ban'
    }
  ]
};

const contract_dispute = {
  decisions: [
    {
      text: 'Не выбрано',
      value: ''
    },
    {
      text: 'Удовлетворить Owner',
      value: 'fulfill_owner'
    },
    {
      text: 'Удовлетворить Tenant',
      value: 'fulfill_tenant'
    }
  ],
  reasons: [
    {
      text: 'Передать 100% залога',
      value: 'return_deposit'
    }
  ]
};

const user_documents_verification = {
  decisions: [
    {
      text: 'Не выбрано',
      value: ''
    },
    {
      text: 'Подтвердить',
      value: 'accepted'
    },
    {
      text: 'Отклонить',
      value: 'rejected'
    }
  ],
  reasons: [
    {
      text: 'Не выбрано',
      value: 'not_selected'
    },
    {
      text: 'Данные соответствуют',
      value: 'data_match'
    },
    {
      text: 'Данные не соответствуют скану',
      value: 'data_not_match'
    },
    {
      text: 'Скан плохого качества',
      value: 'scan_quality'
    },
    {
      text: 'Не все сканы',
      value: 'not_all_scans'
    },
    // {
    //   text: 'Данные уже есть в системе',
    //   value: 'not_all_scans'
    // }
  ]
};

const object_documents_verification = {
  decisions: [
    {
      text: 'Не выбрано',
      value: ''
    },
    {
      text: 'Подтвердить',
      value: 'accepted'
    },
    {
      text: 'Отклонить',
      value: 'rejected'
    }
  ],
  reasons: [
    {
      text: 'Не выбрано',
      value: 'not_selected'
    },
    {
      text: 'Данные соответствуют',
      value: 'data_match'
    },
    {
      text: 'Данные не соответствуют скану',
      value: 'data_not_match'
    },
    {
      text: 'Скан плохого качества',
      value: 'scan_quality'
    },
    {
      text: 'Не все сканы',
      value: 'not_all_scans'
    }
  ]
};

const fake_object = {
  decisions: [
    {
      text: 'Не выбрано',
      value: ''
    },
    {
      text: 'Подтвердить',
      value: 'accepted'
    },
    {
      text: 'Отклонить',
      value: 'rejected'
    }
  ],
  reasons: [
    {
      text: 'Данные соответствуют',
      value: 'data_match'
    },
    {
      text: 'Данные не соответствуют',
      value: 'data_not_match'
    }
  ]
};
export default {
  chat_abuse,
  review_dispute,
  contract_dispute,
  user_documents_verification,
  object_documents_verification,
  fake_object
};