<template>
  <div class="task-footer">
    <ip-select
      :options="decisions"
      label="Решение"
      placeholder="Не выбрано"
      @onSelect="selectDecision"
      :selected-item="decision"
      v-validate="{required: true}"
      v-model="decision"
      name="decision"
      :invalid="errors.has('decision')"
      open="top"
      :disabled="isClosedTask"
    />
    <ip-select
      :options="reasons"
      label="Резолюция"
      placeholder="Не выбрано"
      @onSelect="value => reason = value"
      :selected-item="reason"
      v-validate="{required: true}"
      v-model="reason"
      name="reason"
      :invalid="errors.has('reason')"
      open="top"
      :disabled="isClosedTask"
    />
    <ip-text-input
      name="comment"
      id="comment"
      v-model="comment"
      label="Комментарий"
      placeholder="Ввести..."
      :disabled="isClosedTask"
    />
    <ip-button
      theme="rounded"
      color="blue"
      :disabled="isClosedTask"
      @click="closeTask">
      Сохранить
    </ip-button>
  </div>
</template>

<script>
import IpSelect from "../IpSelect";
import IpTextInput from "../IpTextInput";
import IpButton from "../IpButton";
import taskOptions from "../../consts/taskOptions";
import apiRoutes from '../../consts/apiRoutes';

export default {
  components: {
    IpSelect,
    IpTextInput,
    IpButton
  },

  props: {
    task: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      decisions: [],
      reasons: [],
      reason: '',
      decision: '',
      comment: ''
    };
  },

  mounted() {
    this.decisions = taskOptions[this.task.type].decisions;
    this.reasons = taskOptions[this.task.type].reasons;

    if (this.isClosedTask) {
      this.decision = this.task.decision;
      this.reason = this.task.reason;
      this.comment = this.task.comment;
    }
  },

  computed: {
    isClosedTask() {
      return this.task.status === 'closed';
    }
  },

  methods: {
    selectDecision(decision) {
      this.decision = decision;

      switch(this.task.type) {
        case 'chat_abuse': {
          if (decision === 'rejected') {
            this.reason = 'no_violation';
            return;
          }
          if (decision === 'accepted') {
            this.reason = 'not_selected';
            return;
          }
          break;
        }
        case 'review_dispute': {
          if (decision === 'rejected') {
            this.reason = 'no_violation';
            return;
          }
          if (decision === 'accepted') {
            this.reason = 'not_selected';
            return;
          }
          break;
        }
        case 'contract_dispute': {
          this.reason = 'return_deposit';
          break;
        }
        case 'user_documents_verification': {
          if (decision === 'rejected') {
            this.reason = 'not_selected';
            return;
          }
          if (decision === 'accepted') {
            this.reason = 'data_match';
            return;
          }
          break;
        }
        case 'object_documents_verification': {
          if (decision === 'rejected') {
            this.reason = 'not_selected';
            return;
          }
          if (decision === 'accepted') {
            this.reason = 'data_match';
            return;
          }
          break;
        }
        case 'fake_object': {
          if (decision === 'rejected') {
            this.reason = 'not_selected';
            return;
          }
          if (decision === 'accepted') {
            this.reason = 'data_not_match';
            return;
          }
          break;
        }
        default: {
          break;
        }
      }
    },

    async closeTask() {
      const isValid = await this.$validator.validate();

      if (!isValid) {
        return;
      }

      try {
        const data = {
          decision: this.decision,
          reason: this.reason,
          comment: this.comment
        };

        if (this.reason === 'not_selected') {
          delete data.reason;
        }

        await this.axios.post(`${apiRoutes.closeTask}${this.task.id}`, data);
        this.$router.push({ name: 'tasks' });
      } catch(error) {
        console.warn(error);
      }
    }
  }
}
</script>

<style scoped lang="scss">
.task-footer{
  display: flex;
  align-items: flex-end;
  ::v-deep .ip-select {
    margin-right: 10px;
    flex: 1 1 auto;
    width: 15%;
  }
  ::v-deep .ip-text-input{
    margin-right: 10px;
    flex: 2 0 auto;
  }
  ::v-deep .ip-button{
    flex: none;
  }
}
</style>