<template>
  <div class="contract-history">
    <div
      v-for="(el, index) in history"
      :key="index"
      class="contract-history__elem">
      <div class="date">
        {{ el.date | moment('DD. MM. YYYY') }}
      </div>
      <div class="text">
        {{ actionType(el.action) }}
      </div>
    </div>
  </div>
</template>

<script>

const actionType = {
  // initialized: 'Договор инициирован',
  initialized : 'Договор подписан',
  successful_payment : 'Успешная оплата',
  non_payment : 'Неоплата',
  // : 'Оплата долга'
};

export default {
  name: 'contract-history',

  props: {
    history: {
      type: Array,
      required: true
    }
  },

  methods: {
    actionType(action) {
      return actionType[action];
    }
  }
}
</script>

<style scoped lang="scss">
.contract-history{
  .contract-history__elem{
    display: flex;
    align-items: flex-start;
    position: relative;
    .date{
      position: relative;
      padding-right: 40px;
      padding-bottom: 12px;
      width: 130px;
      &:after{
        background: $scienceBlue;
        width: 9px;
        height: 9px;
        border-radius: 50%;
        content: '';
        position: absolute;
        right: 15px;
        top: 4px;
      }
      &:before{
        background: $scienceBlue;
        width: 1px;
        top: 4px;
        bottom: -4px;
        content: '';
        position: absolute;
        right: 19px;
      }
    }
    .text{
      position: relative;
    }
    &:last-child{
      .date{
        &:before{
          display: none;
        }
      }
    }
  }
}
</style>