<template>
  <div class="wrapper__body user-document">
    <div class="wrapper__body__elem">
      <div class="wrapper__body__elem__title">
        Введённые данные
      </div>
      <div class="wrapper__body__elem__info">
        <div class="wrapper__body__elem__info__title">
          Фамилия
        </div>
        <div class="wrapper__body__elem__info__description">
          {{ task.data.user.details.lastName || '-' }}
        </div>
        <div class="wrapper__body__elem__info__title">
          Имя 
        </div>
        <div class="wrapper__body__elem__info__description">
          {{ task.data.user.details.firstName || '-' }}
        </div>
        <div class="wrapper__body__elem__info__title">
          Отчество 
        </div>
        <div class="wrapper__body__elem__info__description">
          {{ task.data.user.details.middleName || '-' }}
        </div>
        <div class="wrapper__body__elem__info__title">
          Дата рождения 
        </div>
        <div class="wrapper__body__elem__info__description">
          {{ task.data.user.details.birthdate || '-' | moment('DD.MM.YYYY') }}
        </div>
        <div class="wrapper__body__elem__info__title">
          Номер паспорта
        </div>
        <div class="wrapper__body__elem__info__description">
          {{ task.data.user.details.passportNumber || '-' }}
        </div>
        <div class="wrapper__body__elem__info__title">
          Кем выдан паспорт
        </div>
        <div class="wrapper__body__elem__info__description">
          {{ task.data.user.details.passportIssuedBy || '-' }}
        </div>
        <div class="wrapper__body__elem__info__title">
          Когда выдан
        </div>
        <div class="wrapper__body__elem__info__description">
          {{ task.data.user.details.passportIssuedDate || '-' | moment('DD.MM.YYYY') }}
        </div>
        <div class="wrapper__body__elem__info__title">
          Данные о регистрации
        </div>
        <div class="wrapper__body__elem__info__description">
          {{ task.data.user.details.registrationAddress || '-' }}
        </div>
        <div class="wrapper__body__elem__info__title">
          Идентификационный код
        </div>
        <div class="wrapper__body__elem__info__description">
          {{ task.data.user.details.ipn || '-' }}
        </div>
      </div>
    </div>
    <div class="wrapper__body__elem">
      <div class="wrapper__body__elem__title">
        Прикреплённые сканы
      </div>
      <div class="wrapper__body__elem__info">
        <img
          v-for="(document, index) in task.data.documents"
          :key="index"
          :src="documentBuffer(document)"
          :alt="'document-'+index">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    task: {
      type: Object,
      required: true
    }
  },

  methods: {
    documentBuffer(document) {
      const buffer = new Buffer.from(document.buffer, 'base64').toString('base64');
      return `data:image/${document.extension};base64,${buffer}`;
    }
  }
}
</script>

<style scoped lang="scss">
.user-document{
  img{
    width: 100%;
    margin-bottom: 8px;
  }
}
</style>