<template>
  <div class="wrapper__body contract">
    <div class="wrapper__body__elem">
      <div class="wrapper__body__elem__title">
        Данные
      </div>
      <div class="wrapper__body__elem__info noscroll">
        <div class="wrapper__body__elem__info__title">
          Спор с
        </div>
        <div class="wrapper__body__elem__info__description">
          <router-link :to="{ name: 'user', params: { id: task.data.contract.tenant.id } }">
            {{ userName }}
          </router-link>
        </div>
        <div class="wrapper__body__elem__info__title">
          Причина 
        </div>
        <div class="wrapper__body__elem__info__description">
          {{ reason }}
        </div>
        <div class="wrapper__body__elem__info__title">
          Комментарий 
        </div>
        <div class="wrapper__body__elem__info__description">
          {{ task.data.description }}
        </div>
      </div>
      <div class="wrapper__body__elem__info">
        <div class="wrapper__body__elem__info__title">
          История 
        </div>
        <div class="wrapper__body__elem__info__description">
          <contract-history :history="task.data.history"/>
        </div>
      </div>
    </div>
    <div class="wrapper__body__elem">
      <div class="wrapper__body__elem__title">
        Договор
      </div>
      <div class="wrapper__body__elem__info">
        <div class="tabs">
          <div
            class="tab"
            :class="{'active': currentTab === 'data' }"
            @click="selectTab('data')">
            Отображение данных
          </div>
          <div
            class="tab"
            :class="{'active': currentTab === 'docs' }"
            @click="selectTab('docs')">
            PDF документ
          </div>
        </div>
        <template v-if="currentTab === 'data'">
          <div class="wrapper__body__elem__info__title">
            Дата заключения договора 
          </div>
          <div class="wrapper__body__elem__info__description">
            {{ task.data.contract.startDate | moment('DD. MM. YYYY')}}
          </div>
          <div class="wrapper__body__elem__info__title">
            Дата старта договора (заселения)
          </div>
          <div class="wrapper__body__elem__info__description">
            {{ task.data.contract.startDate | moment('DD. MM. YYYY')}}
          </div>
          <div class="wrapper__body__elem__info__title">
            Дата прекращения договора
          </div>
          <div class="wrapper__body__elem__info__description">
              {{ task.data.contract.endDate | moment('DD. MM. YYYY')}}
          </div>
          <div class="wrapper__body__elem__info__title">
            Дата ежемесячной оплаты 
          </div>
          <div class="wrapper__body__elem__info__description">
            {{ task.data.contract.payDate }} число
          </div>
          <div class="wrapper__body__elem__info__title">
            Размер залога
          </div>
          <div class="wrapper__body__elem__info__description">
            {{ task.data.contract.deposit }}грн
          </div>
        </template>
        
        <template v-else>
          <vue-pdf-app :pdf="contractBuffer" :config="config" />
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import ContractHistory from "../../Contract/ContractHistory";

const reasons = {
  finished: 'Окончание договора',
  rules_violation: 'Нарушение условий проживания',
  personal: 'Личные причины'
};

export default {
  props: {
    task: {
      type: Object,
      required: true
    }
  },

  components: {
    ContractHistory
  },

  data() {
    return {
      currentTab: 'data',
      config: {
        toolbar: {
          toolbarViewerLeft: { 
            findbar: false 
          },
          toolbarViewerMiddle: false,
          toolbarViewerRight: false,
          sidebar: false
        },
        secondaryToolbar: false
      },
      contractBuffer: null
    };
  },

  mounted() {
    const buffer = new Buffer.from(this.task.data.documents[0].buffer, 'base64');
    this.contractBuffer = buffer.buffer;
  },

  computed: {
    userName() {
      return `${this.task.data.contract.tenant.details.firstName || ''} ${this.task.data.contract.tenant.details.lastName || ''}`;
    },

    reason() {
      return reasons[this.task.data.reason];
    }
  },

  methods: {
    selectTab(tab) {
      this.currentTab = tab;
    }
  }
}
</script>

<style scoped lang="scss">
.contract{
  .tabs{
    display: inline-flex;
    align-items: center;
    border-radius: 19px;
    background: $athensGray;
    padding: 4px;
    margin-bottom: 16px;
    .tab{
      cursor: pointer;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 17px;
      text-align: center;
      color: $black;
      padding: 7px 12px;
      &.active{
        background: $scienceBlue;
        color: $white;
        border-radius: 19px;
      }
    }
  }
}
</style>